"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShiftDetails = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const translate_1 = require("../../translate");
const Flex_1 = require("../Flex");
const Text_1 = require("../Text");
const ShiftDetails = (props) => {
    const t = (0, translate_1.useTranslations)();
    return ((0, jsx_runtime_1.jsxs)(Flex_1.Flex, { col: true, styles: { textAlign: 'center' }, children: [(0, jsx_runtime_1.jsx)(Text_1.Text, { bold: true, color: props.color, noWrap: true, fontSize: 14, children: `${props.startTime} – ${props.endTime}` }), (0, jsx_runtime_1.jsx)(Text_1.Text, { color: props.color, noWrap: true, fontWeight: 500, fontSize: 14, children: props.breakTime ? t.break_n_min(props.breakTime) : t.no_break() })] }));
};
exports.ShiftDetails = ShiftDetails;
