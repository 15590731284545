"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResourceTitle = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const schedule_1 = require("@bemlo/schedule");
const WeekTitle_1 = require("./WeekTitle");
const constants_1 = require("../../../../constants");
const elements_1 = require("../../../../elements");
const translate_1 = require("../../../../translate");
const ResourceTitle = (props) => {
    const t = (0, translate_1.useTranslations)();
    const time = (0, schedule_1.shiftsToWorkHours)(props.bookedShifts);
    const nameTitle = props.title === 'name' ? (props.resourceUrl ? ((0, jsx_runtime_1.jsx)(elements_1.Link, { to: props.resourceUrl, styles: {
            color: constants_1.Color.BLUE,
            fontWeight: constants_1.theme.font.weight.bold,
            fontSize: constants_1.theme.font.size[6.5],
        }, children: props.name })) : ((0, jsx_runtime_1.jsx)(elements_1.Text, { fontSize: constants_1.theme.font.size[6.5], color: constants_1.Color.BLUE, bold: true, children: props.name }))) : null;
    return ((0, jsx_runtime_1.jsx)(elements_1.Div, { styles: { height: '100%' }, children: (0, jsx_runtime_1.jsxs)(elements_1.Flex, { gap: 2, items: "center", styles: { p: 2 }, children: [nameTitle && ((0, jsx_runtime_1.jsx)(elements_1.Avatar, { type: props.avatarUrl ? 'image' : 'initials', name: props.name, image: props.avatarUrl, size: "sm" })), (0, jsx_runtime_1.jsxs)(elements_1.Flex, { col: true, gap: 0.5, children: [nameTitle ?? (0, jsx_runtime_1.jsx)(WeekTitle_1.WeekTitle, { small: true, week: props.week }), (0, jsx_runtime_1.jsxs)(elements_1.Text, { fontSize: constants_1.theme.font.size['6.5'], color: constants_1.Color.DARKEST_MEDIUM_GREY, fontWeight: constants_1.theme.font.weight.book, children: [t.$date.formatDuration({ hours: time }), " /", ' ', props.bookedShifts.length, ' ', props.bookedShifts.length === 1
                                    ? t.shifts.shift_one().toLocaleLowerCase()
                                    : t.shifts.shift_other().toLocaleLowerCase()] })] })] }) }));
};
exports.ResourceTitle = ResourceTitle;
