"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShiftVariantTitle = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const ShiftDetails_1 = require("./ShiftDetails");
const constants_1 = require("../../constants");
const Flex_1 = require("../Flex");
const ShiftVariantTitle = (props) => {
    return ((0, jsx_runtime_1.jsx)(Flex_1.Flex, { styles: {
            p: 0.5,
            minHeight: 52,
            justifyContent: 'center',
            alignItems: 'center',
        }, children: (0, jsx_runtime_1.jsx)(ShiftDetails_1.ShiftDetails, { ...props.shift, color: constants_1.Color.DARK_GREY }) }));
};
exports.ShiftVariantTitle = ShiftVariantTitle;
