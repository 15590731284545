"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useShiftPresets = void 0;
const lodash_1 = require("lodash");
const react_1 = require("react");
const toPresetKey = (shift) => {
    return `${shift.startTime}-${shift.endTime}-${shift.breakTime}-${shift.scheduleResourceId}-${shift.comment}`;
};
const useShiftPresets = ({ shifts, standard, }) => {
    const [draft, setDraft] = (0, react_1.useState)([]);
    return (0, react_1.useMemo)(() => {
        const combinedData = [
            ...shifts,
            ...draft,
            ...standard.map(({ id, ...data }) => ({
                ...data,
                standardShiftTypeId: id,
            })),
        ];
        const withKey = combinedData.map((data) => {
            return {
                key: toPresetKey(data),
                startTime: data.startTime,
                endTime: data.endTime,
                breakTime: data.breakTime,
                comment: data.comment,
                scheduleResourceId: data.scheduleResourceId,
                standardShiftTypeId: data.standardShiftTypeId,
            };
        });
        // Saved presets should render at the top of the list, then sorted by time and properties
        const sorted = withKey.sort((a, b) => {
            if (a.standardShiftTypeId && !b.standardShiftTypeId) {
                return -1;
            }
            if (!a.standardShiftTypeId && b.standardShiftTypeId) {
                return 1;
            }
            if (a.startTime !== b.startTime) {
                return a.startTime.localeCompare(b.startTime);
            }
            if (a.endTime !== b.endTime) {
                return a.endTime.localeCompare(b.endTime);
            }
            if (a.breakTime !== b.breakTime) {
                return a.breakTime - b.breakTime;
            }
            return a.comment.localeCompare(b.comment);
        });
        // Remove duplicates, keeping the ones with standardShiftTypeId
        // important that list is sorted before this step
        const unique = (0, lodash_1.uniqBy)(sorted, 'key');
        // Determine if break times differ for the same start and end times which means
        // badges should be displayed for the user to select the correct break time
        const hasParallelPresetsWithDifferentBreakTimes = (() => {
            const breaksBySlot = unique.reduce((acc, shift) => {
                const key = `${shift.startTime}-${shift.endTime}`;
                if (!acc[key]) {
                    acc[key] = new Set();
                }
                acc[key].add(shift.breakTime);
                return acc;
            }, {});
            return Object.values(breaksBySlot).some((breakTimes) => breakTimes.size > 1);
        })();
        const createPreset = (shift) => {
            const key = toPresetKey(shift);
            const existing = unique.find((preset) => preset.key === key);
            if (existing)
                return existing;
            setDraft((curr) => [...curr, shift]);
            return { ...shift, key, standardShiftTypeId: undefined };
        };
        return {
            presets: unique,
            hasParallelPresetsWithDifferentBreakTimes,
            createPreset,
            toPresetKey,
        };
    }, [shifts, standard, draft]);
};
exports.useShiftPresets = useShiftPresets;
