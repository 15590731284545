"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WeekTitle = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const constants_1 = require("../../constants");
const translate_1 = require("../../translate");
const Text_1 = require("../Text");
const WeekTitle = (props) => {
    const t = (0, translate_1.useTranslations)();
    return ((0, jsx_runtime_1.jsxs)(Text_1.Text, { color: constants_1.Color.DARK_GREY, size: 5, py: 2, bold: true, children: [t.week(), " ", props.week] }));
};
exports.WeekTitle = WeekTitle;
