"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DigitInput = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const zod_1 = require("zod");
const Input_1 = require("./Input");
const translate_1 = require("../../translate");
// Tests that the string is only digits with an optional minus sign
const isValidNumber = (value) => /^-?\d+$/.test(value);
const DigitInput = (props) => {
    const t = (0, translate_1.useTranslations)();
    const [value, setValue] = (0, react_1.useState)(props.value ? props.value.toString() : undefined);
    const schema = zod_1.z
        .string()
        .refine((value) => value === '' || (value && isValidNumber(value)), {
        message: t.must_be_a_number(),
    })
        .superRefine((value, ctx) => {
        if (!props.schema)
            return true;
        if (value && !isValidNumber(value))
            return false;
        const parseResult = props.schema.safeParse(parseInt(value));
        if (!parseResult.success) {
            const issue = {
                code: zod_1.z.ZodIssueCode.custom,
                path: [],
                message: parseResult.error.issues[0].message,
            };
            ctx.addIssue(issue);
            return false;
        }
        return true;
    });
    const handlePassValue = (value = '') => {
        props.onChange?.(isValidNumber(value) ? parseInt(value) : undefined);
    };
    const handleValueChange = (value) => {
        const parsedValue = value ? value.replace(/[^\d-]/g, '') : undefined;
        setValue(parsedValue);
        handlePassValue(parsedValue);
    };
    const handleBlur = (e) => {
        // Regular expression to match the patterns:
        // - Leading zeros (e.g., '003')
        // - Minus sign followed by zeros and a number (e.g., '-0001')
        const newValue = value?.replace(/^(-?)0*(\d+)$/, (match, minus, num) => {
            // If there is a minus sign and the number is not zero, keep the minus
            return (minus && num !== '0' ? '-' : '') + num;
        });
        setValue(newValue || '0');
        props.onBlur?.(e);
    };
    return ((0, jsx_runtime_1.jsx)(Input_1.Input, { ...props, onChange: handleValueChange, onBlur: handleBlur, type: "text", value: value, schema: schema }));
};
exports.DigitInput = DigitInput;
