"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.restTimeBetweenViolationWhenAddingShiftToExistingSchedule = exports.restTimeBetweenViolationFor = void 0;
const enums_1 = require("@bemlo/enums");
const utils_1 = require("@bemlo/utils");
const restTimeBetweenViolationFor = (shift1, shift2, ruleSet) => {
    const { restTimeBetweenShiftsRequirement: { requiredRestTimeInMinutes }, } = ruleSet;
    const [firstShift, secondShift] = shift1.start.isBefore(shift2.start)
        ? [shift1, shift2]
        : [shift2, shift1];
    const restTimeInMinutes = secondShift.start.diff(firstShift.end, 'minutes');
    if (restTimeInMinutes >= requiredRestTimeInMinutes) {
        return undefined;
    }
    return [
        {
            shiftId: firstShift.id,
            violationType: enums_1.SchedulingViolationType.REQUIRED_REST_BETWEEN_SHIFTS,
            violatingShift: secondShift,
        },
        {
            shiftId: secondShift.id,
            violationType: enums_1.SchedulingViolationType.REQUIRED_REST_BETWEEN_SHIFTS,
            violatingShift: firstShift,
        },
    ];
};
exports.restTimeBetweenViolationFor = restTimeBetweenViolationFor;
const restTimeBetweenViolationWhenAddingShiftToExistingSchedule = (newShift, schedule, ruleSet) => {
    const violations = schedule
        .flatMap((shift) => (0, exports.restTimeBetweenViolationFor)(shift, newShift, ruleSet))
        .filter(utils_1.isTruthy)
        .filter((violation) => violation.shiftId === newShift.id);
    return violations;
};
exports.restTimeBetweenViolationWhenAddingShiftToExistingSchedule = restTimeBetweenViolationWhenAddingShiftToExistingSchedule;
