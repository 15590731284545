"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shiftToDayjs = void 0;
const date_1 = require("@bemlo/date");
const shiftToDayjs = (shift) => {
    const start = (0, date_1.dayjsEn)(shift.date).time(shift.startTime);
    const end = (0, date_1.dayjsEn)(shift.date).time(shift.endTime);
    return {
        start,
        end: end.isBefore(start) || end.isSame(start) ? end.add(1, 'day') : end,
    };
};
exports.shiftToDayjs = shiftToDayjs;
