"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingTitle = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const schedule_1 = require("@bemlo/schedule");
const constants_1 = require("../../constants");
const translate_1 = require("../../translate");
const Avatar_1 = require("../Avatar");
const Div_1 = require("../Div");
const Flex_1 = require("../Flex");
const Link_1 = require("../Link");
const Text_1 = require("../Text");
const BookingTitle = (props) => {
    const t = (0, translate_1.useTranslations)();
    const time = (0, schedule_1.shiftsToWorkHours)(props.shifts);
    return ((0, jsx_runtime_1.jsx)(Div_1.Div, { styles: { height: '100%' }, children: (0, jsx_runtime_1.jsxs)(Flex_1.Flex, { gap: 2.5, items: "center", styles: { p: 2 }, children: [(0, jsx_runtime_1.jsx)(Avatar_1.Avatar, { styles: {
                        border: props.isBooked
                            ? `2px solid ${constants_1.Color.GREEN}`
                            : `2px solid ${constants_1.Color.LIGHT_GREEN}`,
                    }, type: props.avatarUrl ? 'image' : 'initials', name: props.name, image: props.avatarUrl }), (0, jsx_runtime_1.jsxs)(Flex_1.Flex, { col: true, gap: 0.5, children: [props.candidateUrl ? ((0, jsx_runtime_1.jsx)(Link_1.Link, { to: props.candidateUrl, styles: {
                                color: constants_1.Color.BLUE,
                                fontWeight: constants_1.theme.font.weight.bold,
                                fontSize: constants_1.theme.font.size[6.5],
                            }, children: props.name })) : ((0, jsx_runtime_1.jsx)(Text_1.Text, { fontSize: constants_1.theme.font.size[6.5], color: constants_1.Color.BLUE, bold: true, children: props.name })), (0, jsx_runtime_1.jsxs)(Text_1.Text, { fontSize: constants_1.theme.font.size['6.5'], color: constants_1.Color.DARKEST_MEDIUM_GREY, fontWeight: constants_1.theme.font.weight.book, children: [t.$date.formatDuration({ hours: time }), " / ", props.shifts.length, ' ', props.shifts.length === 1
                                    ? t.shifts.shift_one().toLocaleLowerCase()
                                    : t.shifts.shift_other().toLocaleLowerCase()] })] })] }) }));
};
exports.BookingTitle = BookingTitle;
