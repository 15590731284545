"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.areAnyShiftsOverlapping = exports.areShiftsOverlapping = void 0;
const shift_to_dayjs_1 = require("./shift-to-dayjs");
const areShiftTimesOverlapping = (shift1, shift2) => {
    return ((shift1.start.isBefore(shift2.start) && shift1.end.isAfter(shift2.start)) ||
        (shift1.start.isAfter(shift2.start) && shift1.start.isBefore(shift2.end)) ||
        shift1.start.isSame(shift2.start));
};
const areShiftsOverlapping = (firstShift, secondShift) => {
    const shift1 = (0, shift_to_dayjs_1.shiftToDayjs)(firstShift);
    const shift2 = (0, shift_to_dayjs_1.shiftToDayjs)(secondShift);
    return areShiftTimesOverlapping(shift1, shift2);
};
exports.areShiftsOverlapping = areShiftsOverlapping;
const areAnyShiftsOverlapping = (shifts) => {
    const sortedShiftStartAndEndTimes = shifts
        .map((shift) => (0, shift_to_dayjs_1.shiftToDayjs)(shift))
        .sort((a, b) => {
        if (a.start.isBefore(b.start))
            return -1;
        if (a.start.isAfter(b.start))
            return 1;
        return 0;
    });
    return sortedShiftStartAndEndTimes.some((_, index) => {
        if (index === sortedShiftStartAndEndTimes.length - 1)
            return false;
        return areShiftTimesOverlapping(sortedShiftStartAndEndTimes[index], sortedShiftStartAndEndTimes[index + 1]);
    });
};
exports.areAnyShiftsOverlapping = areAnyShiftsOverlapping;
