"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Color = void 0;
/**
 * TODO: Standardize colors and use consistent scales (e.g. RED_100 - RED_900).
 * TODO: Rename PURPLE to INDIGO.
 */
var Color;
(function (Color) {
    Color["TRANSPARENT"] = "#00000000";
    Color["BLACK"] = "#000000";
    Color["BLUISH_BLACK"] = "#202040";
    Color["CHARCOAL"] = "#222224";
    Color["CINDER"] = "#24292F";
    Color["DARKER_GREY"] = "#49494B";
    Color["TRANSPARENT_DARKER_GREY"] = "rgba(27.5, 27.5, 30, 0.8)";
    Color["DARK_GREY"] = "#6D6D6F";
    Color["DARKEST_MEDIUM_GREY"] = "#8D8D8F";
    Color["DARK_MEDIUM_GREY"] = "#BDBDBF";
    Color["MEDIUM_GREY"] = "#E5E5E7";
    Color["LEGACY_GRAY"] = "#E8E8E9";
    Color["GREY"] = "#EBEBED";
    Color["TRANSPARENT_GREY"] = "rgba(95, 95, 111, 0.125)";
    Color["LIGHT_GREY"] = "#F6F6F9";
    Color["BACKGROUND_GREY"] = "#fbfbfc";
    Color["WHITE"] = "#FFFFFF";
    Color["DARKEST_BLUE"] = "#062754";
    Color["DARK_BLUE"] = "#0B3F87";
    Color["BLUE"] = "#0E55B8";
    Color["MEDIUM_BLUE"] = "#3A74C8";
    Color["LIGHT_BLUE"] = "#6592D8";
    Color["MEDIUM_LIGHT_BLUE"] = "#89B1EC";
    Color["TRANSPARENT_BLUE"] = "#ACCFFF";
    Color["LIGHTER_BLUE"] = "#CBE0FF";
    Color["LIGHTEST_BLUE"] = "#F0F5FF";
    Color["DARK_PURPLE"] = "#5149D4";
    Color["DARKEST_PURPLE"] = "#4A3DA9";
    Color["DEEP_PURPLE"] = "#5855EB";
    Color["PURPLE"] = "#6C63FF";
    Color["LIGHT_PURPLE"] = "#DBDAF9";
    Color["LIGHTEST_PURPLE"] = "#F0EFFE";
    Color["TRANSPARENT_PURPLE"] = "#E1E0FF";
    Color["VIOLET"] = "#A953B5";
    Color["PINK"] = "#F35D98";
    Color["DARK_RED"] = "#85261D";
    Color["MEDIUM_DARK_RED"] = "#B73528";
    Color["RED"] = "#E84333";
    Color["MEDIUM_RED"] = "#EF796D";
    Color["LIGHT_MEDIUM_RED"] = "#F29289";
    Color["LIGHT_RED"] = "#F5AEA8";
    Color["TRANSPARENT_RED"] = "#F7C9C4";
    Color["LIGHTER_RED"] = "#FCE4E2";
    Color["LIGHTEST_RED"] = "#FEF2F1";
    Color["DARK_YELLOW"] = "#806050";
    Color["GOLDEN_ROD"] = "#DAA520";
    Color["MEDIUM_YELLOW"] = "#F2C94C";
    Color["YELLOW"] = "#FDE68A";
    Color["LIGHT_YELLOW"] = "#FEF3C5";
    Color["DARKEST_GREEN"] = "#053819";
    Color["DARKER_GREEN"] = "#0A7033";
    Color["DARK_GREEN"] = "#0FA74C";
    Color["GREEN"] = "#0CC757";
    Color["MEDIUM_GREEN"] = "#75E1A1";
    Color["LIGHT_GREEN"] = "#BEEFC6";
    Color["LIGHTER_GREEN"] = "#DDFBEA";
    Color["TRANSPARENT_GREEN"] = "#E7FAEF";
})(Color || (exports.Color = Color = {}));
