import { Locale } from '@bemlo/enums'

import { Profession } from '../../generated/graphql'
import { factories } from '../factories'
import { pluralize } from '../utils'

import type { Translation, TranslationBase, TranslationNamespace } from './en'

const COMPANY_NAME = 'Bemlo'
const COMPANY_NAME_POSSESSIVE = 'Bemlos'
const SITE_NAME = 'Bemlo'
const SITE_NAME_POSSESSIVE = 'Bemlos'

const otherJobs = pluralize('annat jobb', 'andra jobb')
const reviews_ = pluralize('recension', 'recensioner')
const userReportedSalaries = pluralize(
  'användarrapporterad lön',
  'användarrapporterade löner',
)

const auth: TranslationNamespace<'auth'> = {
  choose_log_in_method: 'Välj inloggningsmetod',
  click_link_in_email_to_log_in: 'Klicka på länken i mailet för att logga in.',
  code: 'Kod',
  email_address_in_use: 'E-postadressen används redan',
  have_not_registered_yet: 'Inte registrerat dig ännu?',
  insert_code_sent_by_sms: 'Ange koden som skickades till dig via sms.',
  log_in: 'Logga in',
  log_in_at_our_site: `Logga in på ${SITE_NAME}`,
  log_in_description:
    'Se vilka uppdrag och företag vi hittat åt dig sedan du loggade in sist.',
  log_in_with_email: 'Logga in med email',
  log_in_with_sms: 'Logga in med SMS',
  no_account_connected_to_email:
    'Det finns inget konto kopplat till denna emailadress',
  no_account_connected_to_phonenumber:
    'Det finns inget konto kopplat till detta telefonnummer',
  phone_number_in_use: 'Telefonnumret används redan',
  register: 'Registrera dig',
  specify_email_you_used: `Ange e-postadressen du använde när du anmälde dig på ${SITE_NAME}.`,
  specify_phone_number_you_used: `Ange telefonnumret du använde när du anmälde dig på ${SITE_NAME}.`,
}

const base: TranslationBase = {
  about_us: 'Om oss',
  add: 'Lägg till',
  all_departments: 'Alla enheter',
  all_fields_must_be_provided: 'Fyll i de obligatoriska fälten',
  all_professions: 'Alla yrken',
  all_regions: 'Alla regioner',
  all_specializations: 'Alla specialiseringar',
  apply_for_job: 'Sök jobb',
  back: 'Tillbaka',
  blog: 'Blogg',
  cancel: 'Avbryt',
  collective_agreement: 'Kollektivavtal',
  comment_min_length: (minLength: number) =>
    `Kommentaren måste innehålla minst ${minLength} tecken`,
  email: 'E-mail',
  email_placeholder: 'abc@xyz.se',
  gathered_best_jobs_for_you_description:
    'Vi har samlat bemanningsjobb från 30+ bemanningsbolag, så att du enkelt kan hitta det som passar dig bäst.',
  invalid_email: 'E-mailadressen är inte giltig',
  loading: 'Laddar...',
  log_in: 'Logga in',
  mandatory: 'Obligatorisk',
  menu: 'Meny',
  name: 'Namn',
  name_placeholer: 'Namn Namnsson',
  no: 'Nej',
  no_collective_agreement: 'Inget kollektivavtal',
  no_match_found: 'Ingen match hittad',
  norway: 'Norge',
  obligatory: 'Obligatorisk',
  optional: 'frivilligt',
  other: 'Annan',
  phone_number: 'Telefonnummer',
  phone_placeholder: '070-123 45 67',
  procurements: 'Upphandlingar',
  profession: 'Yrke',
  read_more: 'Läs mer',
  region: 'Region',
  remove: 'Ta bort',
  salary_statistics: 'Lönestatistik',
  select_profession: 'Välj yrke',
  share: 'Dela',
  show_all: 'Visa alla',
  show_more: 'Visa fler',
  staffing_tips: 'Bemanningstips',
  submit: 'Skicka',
  submitting: 'Skickar...',
  sweden: 'Sverige',
  table_of_contents: 'Innehållsförteckning',
  update: 'Uppdatera',
  whats_your_profession: 'Vilket är ditt yrke?',
  workers_in: (profession: Profession) =>
    base.$profession(profession, 'plural').toLowerCase(),
  yes: 'Ja',
  ...factories(Locale.SV),
}

const phone_number: TranslationNamespace<'phone_number'> = {
  enter_valid_number_which_country_code:
    'Vänligen ange ett korrekt telefonnummer med landsnummer (+xx)',
  invalid: 'Ogiltigt telefonnummer',
  mandatory_field: 'Telefonnummer är ett obligatoriskt fält',
  too_short: 'Telefonnumret är för kort',
}

const blog: TranslationNamespace<'blog'> = {
  blog_heading: 'Artiklar om bemanningsbranschen',
  blog_paragraph_1: `På ${SITE_NAME} gillar vi att skriva artiklar som hjälper sjuksköterskor och läkare att förstå vårdbemanningsbranschen bättre.`,
  blog_paragraph_2: 'Något du vill att vi ska skriva om?',
  comment: 'Kommentar',
  mail_us_at: 'Maila oss på',
  no_blog_posts_yet: 'Det finns inga blogginlägg ännu.',
  published: 'Publicerad',
  read_on: (siteName) => `Läs på ${siteName}`,
  reading_time: (minutes) => `${minutes} min läsning`,
  seo_title: 'Läs artiklar om vårdbemanning',
  updated: 'Uppdaterad',
  write_a_comment: 'Skriv en kommentar',
}

const companiesList: TranslationNamespace<'companiesList'> = {
  best_for_doctors: 'Bästa bemanningsbolagen för läkare',
  best_for_nurses: 'Bästa bemanningsbolagen för sjuksköterskor',
  doctors: 'läkare',
  find_best_companies: 'Hitta det bästa bemanningsbolaget inom vården',
  find_best_companies_for: (profession: string) =>
    `Hitta det bästa bemanningsbolaget för ${profession}`,
  keywords: ['recensioner', 'bemanningsbolag'],
  nurses: 'sjuksköterskor',
  nurses_and_doctors: 'sjuksköterskor och läkare',
  read_what_others_think: (professions: string) =>
    `Läs vad andra ${professions} har skrivit om bemmanningsbolagen innan du väljer. Jämför lön och upphandlinger.`,
  see_also: 'Se också:',
}

const companyFaq: TranslationNamespace<'companyFaq'> = {
  apply_now: 'Gör en intresseanmälan',
  does_company_have_collective_agreement: (name: string) =>
    `Har ${name} kollektivavtal?`,
  how_many_consultants_work_at_company: (name: string) =>
    `Hur många konsulter jobbar på ${name}?`,
  no_company_does_not_have_collective_agreement: (name: string) =>
    `Nej – ${name} har inte kollektivavtal.`,
  number_of_consultants_text: (count: number | null, name: string) =>
    count
      ? `Om man räknar om antalet konsulter till heltidsanställda så jobbar ungefär ` +
        `${count} konsulter heltid på ${name}. Den exakta siffran är svår att veta.`
      : 'Vi har tyvärr inte denna information.',
  read_more: 'Läs mer här',
  yes_company_has_collective_agreement: (name: string) =>
    `Ja – ${name} har kollektivavtal!`,
}

const companyPage: TranslationNamespace<'companyPage'> = {
  about_company: (name: string) => `Om ${name}`,
  authorization_description:
    'Almega certifierar bemanningsföretag som har kollektivavtal och uppfyller ett visst antal kriterier.',
  authorized_collective_agreement: 'Auktoriserat av Almega',
  company_job_postings: (name: string) => `${name} – Lediga uppdrag`,
  company_page_description: (name: string) =>
    `Läs vad andra tycker om att jobba på ${name}. Se löner och i vilka regioner ` +
    `${name} är upphandlade. Gör en intresseanmälan för att jobba på ${name} direkt på ` +
    `${SITE_NAME}.`,
  find_jobs: 'Sök jobb',
  go_back: 'Till huvudsidan',
  go_to_company_list: 'Till företagslistan',
  in_region: (region: string) => `i ${region}`,
  jobs: 'Lediga jobb',
  new: 'Nytt',
  no_procurements_found_for: (name: string) =>
    `Vi kunde inte hitta några upphandlingar för ${name}.`,
  other_top_ranked_companies: 'Andra topprankade företag',
  read_more: 'Läs mer',
  respond_to_contact_request_from: (name: string) =>
    `Besvara kontaktförfrågan från ${name}`,
  reviews: 'Recensioner',
  salaries: 'Löner',
  salary_statistics: 'Lönestatistik',
  see_other_consultant_salaries: (
    profession: Profession,
    regionCount: number,
  ) =>
    `Se vad andra ${base.workers_in(
      profession,
    )} har för lön i över ${regionCount} regioner.`,
  see_user_reported_salaries: (count?: number) =>
    `Se${count ? ` ${count}` : ''} ${userReportedSalaries(count)}`,
  see_what_others_think_of_company: (name: string) =>
    `Läs vad andra tycker om ${name} och jämför företag`,
  summary: 'Översikt',
  the_text_is_taken_from: (source: string) => `Texten är tagen från ${source}`,
  the_text_is_taken_from_companys_website: (name: string) =>
    `Texten är tagen från ${name}s hemsida`,
  you_and_company_are_in_contact: (name: string) =>
    `Du och ${name} har kontakt`,
}

const companyTable: TranslationNamespace<'companyTable'> = {
  ad: 'Annons',
  company: 'Företag',
  find_companies: 'Sök företag',
  missing_companies_info: 'Saknas något företag här? Skicka ett förslag till',
  name: 'Namn',
  number_of_consultants: 'Antal konsulter i arbete',
  rating: 'Betyg',
  rating_ascending: 'Betyg, lägst först',
  rating_descending: 'Betyg, högst först',
  region: 'Region',
  showing_region: 'Visar i region',
  sort: 'Sortera',
  sort_by: 'Sortera efter',
}

const cookieConsent: TranslationNamespace<'cookieConsent'> = {
  accept_all: 'Godkänn alla',
  accept_all_cookies: 'Godkänn alla cookies',
  accept_selected: 'Godkänn urval',
  analytics_cookies: 'Statistik',
  analytics_cookies_description:
    'Hjälper oss förstå hur webbplatsen används så att vi kan förbättra den.',
  choose_cookies: 'Välj vilka cookies som får sparas i din webbläsare.',
  cookies_description:
    'Vi använder cookies för att förbättra din upplevelse på vår webbplats. En del cookies används för att tjänster ska fungera som de ska och andra för att förbättra din upplevelse.',
  custom_selection: 'Välj själv',
  how_we_use_cookies: 'Läs mer om hur vi använder cookies.',
  necessary_cookies: 'Nödvändiga cookies',
  necessary_cookies_description:
    'Nödvändiga cookies används för att hemsidan ska fungera smidigt och säkert. Möjliggör viktiga funktioner som inloggning.',
  we_value_your_privacy: 'Vi värnar om din integritet',
}

const ctaPanel: TranslationNamespace<'ctaPanel'> = {
  compare_companies: 'Jämför företag',
  compare_staffing_companies_for: (profession: Profession) =>
    `Jämför ${
      profession === Profession.NURSE ? 80 : 60
    }+ bemanningsföretag för ${base.workers_in(profession)}!`,
  easily_send_applications_to_companies:
    'Skicka enkelt intresseanmälan till företag',
  see_information_about_salaries_and_benefits:
    'Se information om löner och förmåner',
  see_reviews_from_others_in: (profession: Profession) =>
    `Läs recensioner av andra ${base.workers_in(profession)}`,
}

const deleteProfileRedirect: TranslationNamespace<'deleteProfileRedirect'> = {
  we_are_sorry_to_see_you_leaving: 'Vi är ledsna att se dig lämna Bemlo!',
  you_are_always_welcome_back: 'Du är alltid välkommen tillbaka',
}

const fallback: TranslationNamespace<'fallback'> = {
  an_error_occurred: 'Ett fel inträffade',
  to_the_start_page: 'Till startsidan',
  were_working_on_a_fix: 'Vi jobbar på en lösning',
}

const faq: TranslationNamespace<'faq'> = {
  get_answers_to_your_questions_about_staffing:
    'Få svar på dina frågor om vårdbemanningsbranschen',
  no_questions: 'Vi kunde inte hitta några frågor.',
  questions: 'Frågor',
  subtitle: (email) => (
    <>
      Hittar du inte svaret på din fråga?
      <br />
      Maila oss isåfall på{' '}
      <a href={`mailto:${email}`} style={{ color: 'white' }}>
        {email}
      </a>
      .
    </>
  ),
  title: `Vanliga frågor om vårdbemanning – ${SITE_NAME}`,
}

const footer: TranslationNamespace<'footer'> = {
  career_cta: 'Utvecklare? Sök här.',
  cookie_policy: 'Cookiepolicy',
}

const header: TranslationNamespace<'header'> = {
  find_a_job: 'Hitta jobb',
  home: 'Hem',
  jobs: 'Lediga jobb',
  log_in: 'Logga in',
  log_out: 'Logga ut',
  my_profile: 'Din profil',
  salary_calculator: 'Lönekalkylatorn',
  see_my_profile: 'Se profil',
  show_the_page_for: 'Visa sidan för',
  switch_language: 'In English',
  vardforbundet: 'Vårdförbundet',
}

const iePopup: TranslationNamespace<'iePopup'> = {
  download_chrome_here: 'Ladda ner Chrome här',
  page_does_not_work_with_ie:
    `Tyvärr funkar inte ${SITE_NAME} så bra med Internet Explorer. ` +
    'Vi rekommenderar att du använder en annan webläsare som Chrome eller Firefox.',
}

const jobBoard: TranslationNamespace<'jobBoard'> = {
  apply: 'Sök',
  apply_by: 'Ansök senast',
  apply_directly_on_website: () => `Ansök direkt på ${meta.site_name}`,
  bemlo_logotype_alt_text: 'Bemlos logotyp',
  could_not_find_any_matching_published_vacancies:
    'Kunde inte hitta några matchande publicerade uppdrag',
  could_not_find_any_matching_vacancies:
    'Kunde inte hitta några matchande uppdrag',
  create_a_profile: 'Skapa profil',
  create_a_profile_description:
    'Skapa en profil och få erbjudanden med matchande uppdrag direkt från **30+** bemanningsbolag.',
  department_other: 'Verksamheter',
  distance_work: 'Distansarbete',
  earliest_job_start: 'Tidigaste uppdragsstart',
  estimated: 'Uppskattad',
  for_the_job: 'jobbet',
  full_time_description: 'Uppdraget är på 100%',
  hour_short: 'h',
  invoice_verb: 'Fakturera',
  irregular_part_time_description:
    'Uppdraget handlar om enstaka pass då och då',
  job_board_paragraph:
    'Vi har samlat bemanningsjobb från **30+** bemanningsbolag. Du som sjuksköterska och läkare kan enkelt hitta det som passar dig bäst. Vi publicerar alltid lönen så att du kan jämföra upplägg och ersättning. Hitta ditt nästa vårduppdrag idag.',
  job_mediated_by_company: (company: string) => `Jobb förmedlat av ${company}`,
  job_start: 'Uppdragsstart',
  jobs_page_title: 'Lediga bemanningsjobb i Sverige',
  match: 'match',
  month_short: 'mån',
  new: 'Ny',
  no_description: 'Ingen beskrivning',
  profession_title: 'Yrkestitel',
  published_at_date: (date: string) => `Publicerad ${date}`,
  region: 'Region',
  region_header_alt_text: 'Bild på regionen uppdraget utförs i',
  regular_part_time_description: 'Uppdraget är på mellan 20-80%',
  reset_filters: 'Återställ filter',
  salary: (profession: Profession) =>
    profession === Profession.DOCTOR ? 'ersättning' : 'lön',
  show_all_departments: 'Visa alla enheter',
  showing_x_out_of_y_vacancies: (x: number, y: number) =>
    `Visar **${x}** av **${y}** annonser`,
  specialization_other: 'Specialiseringar',
  staffing_job_in_region: (municipality: string) =>
    `Bemanningsuppdrag i ${municipality}`,
  the_job_is_located_in_municipality: (municipality: string) =>
    `Uppdraget utförs i ${municipality}`,
  unknown_date: 'Okänt datum',
  until_further_notice: 'Tillsvidare',
  vacancies_for_company: (companyName?: string) =>
    `Lediga jobb${companyName ? ` hos ${companyName}` : ''}`,
  vacancies_for_nurses_and_doctors:
    'Lediga bemanningsjobb för sjuksköterskor och läkare i Sverige',
  week_number: (week: string) => `Vecka ${week}`,
}

const jobPage: TranslationNamespace<'jobPage'> = {
  application_sent: 'Anmälan skickad',
  apply_now: 'Sök jobbet',
  create_an_account: 'Skapa ett konto',
  create_an_account_description: 'Vill du veta mer om arbetsplatsen?',
  cta_section_heading: 'Bli matchad med bemanningsjobben du alltid drömt om',
  cta_section_paragraph:
    'Jämför bolag och hitta det perfekta uppdraget för dig.',
  explore_similar_jobs: 'Utforska liknande jobb',
  open_map: 'Öppna karta',
  see_more_jobs: 'Se fler jobb',
  see_the_jobs: 'Hitta jobb',
  your_workplace: 'Din arbetsplats',
}

const landingPage: TranslationNamespace<'landingPage'> = {
  apply_now: 'Sök jobb direkt',
  find_the_best_staffing_company: 'Hitta bästa bemanningsföretaget inom vården',
  landing_page_heading_1: 'Bli matchad med bemanningsjobben',
  landing_page_heading_2: 'du alltid drömt om',
  landing_page_paragraph_1: 'Är du redo för lönen du förtjänar?',
  landing_page_paragraph_2:
    'Jämför bolag och hitta det perfekta uppdraget för dig.',
  log_in_at_our_site: `Logga in på ${SITE_NAME}`,
  see_jobs: (count: number) =>
    `Se ${count} ledig${count === 1 ? 't' : 'a'} jobb`,
  see_the_company_rankings: 'Se företagsrankingen',
  see_the_jobs: 'Se lediga jobb',
  seo_description_profession: (profession: Profession) =>
    `Se alla bemanningsjobb för ${base.workers_in(profession)} på ett ställe`,
  so_that_you_can_easily_compare_terms_and_salaries:
    'Så att du enklare kan jämföra villkor och lön',
  we_present_staffing_jobs_from_many_companies:
    'Vi listar bemanningsjobb från flera bolag',
}

const landingPage2: TranslationNamespace<'landingPage2'> = {
  apply_now: 'Sök nu',
  blog_button: 'Läs fler artiklar',
  blog_heading: 'Livet inom bemanning',
  blog_paragraph_for_profession: (profession: Profession) =>
    `På ${SITE_NAME} gillar vi att skriva artiklar som hjälper ${base.workers_in(
      profession,
    )} att förstå vårdbemanningsbranschen bättre.`,
  blog_paragraph_generic: `På ${SITE_NAME} gillar vi att skriva artiklar som hjälper vårdarbetare att förstå vårdbemanningsbranschen bättre.`,
  blog_read_more: 'Läs mer',
  companies_and_many_more: '...och många fler',
  companies_based_on_reviews: (count: number) =>
    `Baserat på ${count} ${reviews_(count)}`,
  companies_heading: 'Vi kopplar ihop dig med de bästa bemanningsbolagen',
  companies_see_the_companies: 'Se företagen',
  create_a_profile: 'Skapa en profil',
  cta_button: 'Hitta mitt drömjobb',
  cta_heading_1: 'Bli matchad med bemanningsjobben',
  cta_heading_2: 'du alltid drömt om',
  cta_paragraph: 'Jämför bolag och hitta det perfekta uppdraget för dig.',
  explanation_button: 'Kom igång på 5 min',
  explanation_heading: `Så funkar ${SITE_NAME}`,
  explanation_step_1_heading: 'Skapa konto',
  explanation_step_1_paragraph: (profession: Profession) =>
    `Genom att skapa ett konto på ${SITE_NAME} samlar du ditt professionella liv som ${base
      .$profession(profession)
      .toLowerCase()} på en plats.`,
  explanation_step_1_paragraph_generic: `Genom att skapa ett konto på ${SITE_NAME} samlar du ditt professionella liv på en plats.`,
  explanation_step_2_heading: 'Hitta jobb',
  explanation_step_2_paragraph:
    'Få tillgång till bemanningsuppdrag från hela marknaden och bli matchad med de som passar dina preferenser.',
  explanation_step_3_heading: 'Börja jobba',
  explanation_step_3_paragraph:
    'Vi ser till att du får flera erbjudanden för varje uppdrag så att du kan maximera din ersättning utan extra jobb.',
  intro_button: 'Se alla uppdrag',
  intro_clarification: 'Kostnadsfritt – kom igång på 5 min!',
  intro_heading_1: (profession: Profession) =>
    `Vill du arbeta som stafett${base
      .$profession(profession)
      .toLowerCase()}? Se alla uppdrag `,
  intro_heading_2: () => '',
  intro_heading_3: () => 'på ett ställe.',
  intro_image_alt: (profession: Profession) =>
    `Ett fotografi av en leende ${base.$profession(profession).toLowerCase()}`,
  intro_paragraph_1: 'Är du redo för lönen du förtjänar?',
  intro_paragraph_2:
    'Vi matchar ihop dig med de bästa jobben från 30+ bemanningsbolag.',
  jobs_button: (count: number) => `Se ${count} ${otherJobs(count)}`,
  jobs_heading_for_profession: (profession: string) =>
    `Vi har jobben för ${profession}`,
  jobs_heading_generic: `Vi har jobben`,
  jobs_subscribe: 'Prenumerera på jobberbjudanden',
  learn_1_buttonText: 'Beräkna din lön',
  learn_1_paragraph: (profession: Profession) =>
    `Räkna ut hur mycket du kan tjäna som ${base
      .$profession(profession)
      .toLowerCase()} per region och verksamhet utifrån din utbildning och erfarenhet.`,
  learn_1_paragraph_generic: `Räkna ut hur mycket du kan tjäna per region och verksamhet utifrån din utbildning och erfarenhet.`,
  learn_1_title: 'Lönekalkylatorn',
  learn_2_buttonText: 'Jämför löner',
  learn_2_paragraph:
    'Läs inrapporterad lönestatistik från kollegor som arbetat inom bemanning tidigare.',
  learn_2_title: 'Lönestatistik',
  learn_3_buttonText: 'Till artiklarna',
  learn_3_paragraph:
    'Hitta svaren på alla dina frågor om sjukvårdsbemanning. Hur fakturerar man? Vad är en direktupphandling? Och mycket mer.',
  learn_3_title: 'Bloggen',
  learn_4_buttonText: 'Se upphandlade löner',
  learn_4_paragraph:
    'Hitta var och för vilket bolag du kan tjäna mest. Vi har alla kommunala och regionala upphandlingar.',
  learn_4_title: 'Upphandlingar',
  learn_5_buttonText: 'Till frågorna',
  learn_5_paragraph: 'Få svar på vanliga frågor inom sjukvårdsbemanning.',
  learn_5_title: 'FAQ',
  learn_heading: 'Lär dig mer om bemanningsbranschen',
}

const leads: TranslationNamespace<'leads'> = {
  companyHeading: (name: string) => `Vill du jobba för ${name}?`,
  education: 'Utbildning',
  education_placeholder: 'Jag är specialiserad inom...',
  heading: 'Bli matchad med flera topprankade bolag',
  interests: 'Intressen',
  interests_placeholder: 'Jag vill jobba inom...',
  pageTitle: 'Sök bemanningsjobb på 60 sekunder',
  paragraph: 'Det här är lite svensk text som vi ska ta bort snart.',
  phone_number: 'Telefonnummer',
  profession: 'I vilken yrkesgrupp söker du jobb?',
  submit: 'Fortsätt',
  thank_you_for_submitting:
    'Tack för ditt intresse! Vi kommer att kontakta dig angående nya spännande uppdrag.',
  when_to_work: 'När kan du börja, och hur länge vill du jobba?',
  when_to_work_placeholder: 'Jag vill börja jobba...',
  where_to_work: 'Var vill du jobba?',
  where_to_work_placeholder: 'Jag vill jobba nära...',
}

const menuItems: TranslationNamespace<'menuItems'> = {
  about_us: 'Om oss',
  blog: 'Blogg',
  companies: 'Rankningslistan',
  jobs: 'Lediga jobb',
  procurements: 'Upphandlingar',
  register: 'Innehållsförteckning',
  salary_calculator: 'Lönekalkylatorn',
  salary_statistics: 'Lönestatistik',
  staffing_tips: 'Bemanningstips',
  vardforbundet: 'Vårdförbundet',
}

const meta: TranslationNamespace<'meta'> = {
  company_name: COMPANY_NAME,
  company_name_possessive: COMPANY_NAME_POSSESSIVE,
  site_description: 'Jämför vårdbemanningsföretag snabbt och enkelt',
  site_keywords: ['bemlo', 'vårdbemanning'],
  site_name: SITE_NAME,
  site_name_possessive: SITE_NAME_POSSESSIVE,
}

const notFound: TranslationNamespace<'notFound'> = {
  page_not_found: 'Sidan kunde ej hittas',
  to_the_start_page: 'Till startsidan',
  you_found_a_page_that_doesnt_exist:
    'Du hittade en sida som tyvärr inte existerar.',
}

const sentry: TranslationNamespace<'sentry'> = {
  errorFormEntry: 'Vänligen fyll i alla obligatoriska fält.',
  errorGeneric:
    'Ett oväntat fel inträffade när formuläret skulle skickas. Var vänlig försök igen.',
  labelClose: 'Stäng',
  labelComments: 'Vad gjorde du?',
  labelEmail: 'Email',
  labelName: 'Namn',
  labelSubmit: 'Skicka',
  subtitle: 'Vårt team har underrättats.',
  subtitle2: 'Om du vill hjälpa till, beskriv vad som hände här nedan',
  successMessage: 'Ditt meddelande har skickats. Tack!',
  title: 'Ett fel har uppstått.',
}

const reviews: TranslationNamespace<'reviews'> = {
  be_the_first_reviewer_of: (name: string) =>
    `Bli den första som recenserar ${name} genom att använda formuläret ovan.`,
  comments: 'Kommentarer',
  confirm: 'Bekräfta',
  email: 'E-mail',
  email_must_be_valid: 'Om en e-mail inte är giltig raderas kommentaren.',
  go_back: 'Tillbaka',
  guest: 'Gäst',
  have_you_worked_at_company: (name: string) => `Har du jobbat för ${name}?`,
  have_you_worked_at_company_info: () =>
    'Vi accepterar endast recensioner från tidigare eller nuvarande anställda.',
  help_others: (name: string) => (
    <>
      Har du erfarenhet av att arbeta på <strong>{name}</strong>? Hjälp andra
      genom att sätta ett betyg!
    </>
  ),
  leave_a_comment: 'Lämna en kommentar',
  license_number: 'Legitimationsnummer',
  moderator: 'Moderator',
  name: 'Namn',
  next: 'Nästa',
  no_reviews_available: 'Inga recensioner tillgängliga.',
  only_show_reviews_from: 'Visa bara recensioner från',
  please_leave_a_star_rating: 'Vänligen lämna ett stjärnbetyg',
  rate: (name: string) => `Betygssätt ${name}`,
  rating: 'Betyg',
  review: 'Recension',
  review_could_not_be_submitted:
    'Din recension kunde inte skickas. Försök igen.',
  review_fraud_warning_subtitle: 'Vi arbetar aktivt för att förebygga fusk.',
  review_fraud_warning_title:
    'En eller flera recensioner på detta bolag har tagits bort p.g.a. legitimitetsskäl.',
  review_security_information: (
    <>
      För att säkerställa att din recension är äkta ber vi dig nu att signera
      din recension med mobilt BankID. Med din signatur intygar du att:
      <ul>
        <li>Du har arbetat på företaget du recenserar</li>
        <li>Du är legitimerad sjuksköterska eller läkare</li>
        <li>Innehållet i din kommentar är sanningsenligt</li>
      </ul>
      Dina personuppgifter kommer <b>aldrig</b> att delas med personer utanför{' '}
      {SITE_NAME}.
    </>
  ),
  start_bankid: 'Starta BankID-appen',
  start_bankid_paragraph:
    'Starta BankID-appen på din telefon och tryck på ikonen för QR-kod i appen för att skanna ' +
    'QR-koden.',
  terms_and_conditions: 'villkor',
  thanks_for_sharing_your_experience:
    'Tack för att du delar med dig av dina erfarenheter!',
  trust_text:
    `${SITE_NAME} bygger på tillit och att de recensioner som ges är ärliga. ` +
    'Ge därför bara kommentarer på ställen du har erfarenhet av — det tjänar alla på i slutändan.',
  we_take_measures_against_manipulation:
    'Vi vidtar åtgärder vid alla försök till att manipulera rankingen.',
  when_you_submit_you_accept_our: 'När du skickar så godkänner du våra',
  write_a_review_for: (name: string) => `Skriv en recension om ${name}...`,
  xxx: 'xxx',
  your_information_will_not_be_shared_with: (name: string) =>
    `Din information kommer inte delas med ${name}.`,
}

const registrationForm: TranslationNamespace<'registrationForm'> = {
  add_profile_information_so_that:
    'Fyll i dina kontaktuppgifter så att arbetsgivare kan kontakta dig med spännande jobberbjudanden som passar dina preferenser',
  additional_contacts_more_label: 'Jag vill ha kontakt med flera företag',
  additional_contacts_more_subtitle: (sitename) =>
    `Jag vill att ${sitename} matchar mig med fler topprankade företag`,
  additional_contacts_none_label: 'Inga fler företag',
  additional_contacts_none_subtitle: 'Bara till det aktuella företaget',
  additional_contacts_title: 'Vill du få kontakt med några fler företag?',
  additional_contacts_url_extension_string: 'kontakter',
  apply_to: (numberOfSelectedEmployers?: number) =>
    `Anmäl intresse till ${numberOfSelectedEmployers} bolag`,
  biography_alternatives_free_text_placeholder:
    'Jag letar efter uppdrag där... (fritext) (valfritt)',
  biography_title: 'Beskriv dig själv kortfattat.',
  biography_url_extension_string: 'biografi',
  boom: 'Sådär!',
  confirm_application: 'Bekräfta anmälan',
  contact_details_email_error_format: 'Vänligen ange en korrekt email',
  contact_details_email_error_obligatory: 'E-post är ett obligatoriskt fält',
  contact_details_email_suggestion: (email: string) => `Menade du ${email}?`,
  contact_details_email_title: 'E-post',
  contact_details_error_message: 'Vänligen ange för- och efternamn',
  contact_details_name_title: 'För- och efternamn',
  contact_details_title: 'Fyll i dina kontaktuppgifter',
  current_employment_status_subtitle: 'Vilket påstående stämmer bäst in på dig',
  current_employment_status_subtitle_update:
    'Vilket alternativ beskriver bäst din nuvarande situation',
  current_employment_status_title: 'Hur ser din nuvarande arbetssituation ut?',
  current_employment_status_title_update: 'Det var ett tag sedan vi såg dig',
  current_employment_status_url_extension_string: 'idag',
  departments_interests_alternatives_box_title: 'Verksamheter',
  departments_interests_free_text_placeholder:
    'Jag vill arbeta med... (fritext)',
  departments_interests_free_text_title: 'Inget alternativ som passar?',
  departments_interests_title:
    'Inom vilken verksamhet är du intresserad av att arbeta?',
  departments_interests_url_extension_string: 'verksamhet',
  error_action_back_to: (siteName: string) => `Tillbaka till ${siteName}`,
  error_action_try_again: 'Testa igen',
  error_paragraph_1: (email: string) => (
    <>
      Något gick fel när din ansökan skulle skickas. Kontakta gärna oss direkt
      på{' '}
      <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
        {email}
      </a>{' '}
      så hjälper vi dig där.
    </>
  ),
  error_paragraph_2: 'Vårt team arbetar hårt på att lösa problemet.',
  error_title: 'Något gick fel',
  existing_account_subtitle: (siteName: string) =>
    `Du verkar redan ha ett konto på ${siteName}.`,
  existing_account_title: (siteName: string) =>
    `Logga in till ditt ${siteName}-konto`,
  experience_alternatives_box_title: 'Erfarenhet',
  experience_free_text_placeholder: 'Jag har arbetat... (fritext)',
  experience_free_text_title: 'Inget alternativ som passar?',
  experience_title: 'Hur lång erfarenhet har du?',
  experience_url_extension_string: 'erfarenhet',
  home_municipality_alternatives_box_title: 'Hemkommun',
  home_municipality_box_placeholder: 'Kommun...',
  home_municipality_extension_string: 'hemkommun',
  home_municipality_free_text_placeholder: 'Har nära till...',
  home_municipality_free_text_title: 'Kommentar',
  home_municipality_title: 'Var bor du idag?',
  how_much_work_full_time_description:
    'Jag är intresserad av att arbeta heltid',
  how_much_work_irregular_part_time_description:
    'Jag vill ta enstaka pass då och då',
  how_much_work_part_time_description:
    'Jag letar efter uppdrag på mellan 20-80%',
  how_much_work_title: 'Hur mycket är du intresserad av att arbeta?',
  how_much_work_url_extension_string: 'omfattning',
  job_types_irregular_parttime_subtitle: 'Timmar och pass hos vårdgivare.',
  job_types_permanent_subtitle:
    'Tillsvidareanställning hos privat eller offentlig vårdgivare.',
  job_types_staffing_subtitle:
    'Kortare eller längre uppdrag för bemanningsbolag.',
  job_types_title: 'Vilka typer av arbeten intresserar dig?',
  job_types_url_extension_string: 'arbeten',
  journal_system_alternatives_box_placeholder: 'Journalsystem...',
  journal_system_alternatives_box_title: 'Journalsystem',
  journal_system_alternatives_free_text_placeholder:
    'Jag har arbetat med... (fritext)',
  journal_system_alternatives_free_text_title: 'Fattas det något system?',
  journal_system_title: 'Vilka journalsystem har du arbetat med?',
  journal_system_url_extension_string: 'journalsystem',
  loading: 'Laddar',
  move_back: 'Tillbaka',
  move_forward: 'Gå vidare',
  norwegian_license_subtitle: 'Har du eller har du sökt norsk legitimation?',
  norwegian_license_title: 'Norsk legitimation',
  norwegian_license_url_extension_string: 'norsk-legitimation',
  page_description:
    'Registrera din profil och sök jobb till alla högt rankade bemanningsföretag för sjuksköterskor och läkare.',
  page_title: 'Sök bemanningsjobb på 60 sekunder',
  please_continue_improving_your_profile:
    'Fortsätt gärna att svara på frågor för att fylla ut din profil.',
  please_tell_us_more_about_you: 'Berätta mer om dig själv',
  profession_title: 'Inom vilken yrkesgrupp söker du jobb?',
  profession_url_extension_string: 'yrke',
  reference_add_first_reference: 'Lägg till din första referens',
  reference_add_reference: 'Lägg till referens',
  reference_add_reference_description:
    'Vi rekommenderar att bjuda in din senaste chef samt två kollegor.',
  reference_added_at: (at: string) => `Tillagd (${at})`,
  reference_button: (hasReferences: boolean) =>
    `Bjud in ${hasReferences ? 'fler ' : ''}referenser`,
  reference_close: 'Stäng',
  reference_entered_at: (at: string) => `Referens lämnad (${at})`,
  reference_extension_string: 'referens',
  reference_no_references: 'Du har inte lagt till några referenser än.',
  reference_save_and_close: 'Spara och stäng',
  reference_subtitle:
    'Bjud in referenser via Bemlo för att spara tid för dig själv, dina referenser och bemanningsbolagen. Dina referenser får ett mail med en länk där de snabbt kan lämna en rekommendation om dig.',
  reference_title: 'Ange referens',
  reference_update_reference: 'Uppdatera referens',
  reference_your_description:
    'Dina referenser delas automatiskt med de arbetsgivare du matchas med.',
  reference_your_title: 'Dina referenser',
  send_application: 'Skicka anmälan',
  send_new_application_to: (name?: string) =>
    `Vill du skicka in en ny intresseanmälan${name ? ` till ${name}` : ''}?`,
  skip_question: 'Hoppa över',
  specialization_alternatives_box_title: 'Specialisering',
  specialization_doctor_url_extension_string: 'specialisering-lak',
  specialization_free_text_placeholder: 'Specialisering inom... (fritext)',
  specialization_free_text_title: 'Saknas din utbildning?',
  specialization_nurse_url_extension_string: 'specialisering-ssk',
  specialization_title: 'Har du någon specialisering?',
  success_welcome_message: 'Nu är första steget klart.',
  swedish_license_subtitle: 'Har du svensk legitimation?',
  swedish_license_title: 'Svensk legitmation',
  swedish_license_url_extension_string: 'svensk-legitimation',
  to_company: (name?: string) => `Till ${name ?? 'företaget'}`,
  to_jobs: 'Till lediga jobb',
  waiting_for_answers: 'Inväntar svar',
  we_recommend_picking_1_3_companies:
    'Vi rekommenderar att du väljer 1-3 bolag.',
  were_handling_your_application: 'Vi behandlar din ansökan så fort vi kan.',
  when_to_start_exact_placeholder: 'Välj datum',
  when_to_start_exact_title: 'När kan du börja tidigast?',
  when_to_start_exact_url_extension_string: 'tidigast',
  which_companies_do_you_want_to_recieve_offers_from:
    'Vilka bolag vill du få erbjudanden från?',
  which_countries_extension_string: 'land',
  which_countries_title: 'I vilka länder är du intresserad av att arbeta?',
  which_regions_affected_by_karens_alternatives_label: (alternative: string) =>
    `Region ${alternative}`,
  which_regions_affected_by_karens_box_placeholder: 'Region...',
  which_regions_affected_by_karens_box_title: 'Regioner',
  which_regions_affected_by_karens_free_text_placeholder:
    'Det är så att... (fritext)',
  which_regions_affected_by_karens_free_text_title: 'Något du vill tillägga?',
  which_regions_affected_by_karens_string: 'karens',
  which_regions_affected_by_karens_title:
    'Har du arbetat för någon av dessa arbetsgivare under det senaste året?',
  which_regions_to_work_in_alternatives_box_title: 'Regioner',
  which_regions_to_work_in_free_text_placeholder:
    'Jag kan arbeta i... (fritext)',
  which_regions_to_work_in_free_text_title: 'Inget alternativ som passar?',
  which_regions_to_work_in_title: 'I vilka regioner vill du arbeta?',
  which_work_form_subtitle: 'Hur vill du arbeta?',
  which_work_form_title: 'Anställningsform',
  which_work_form_url_extension_string: 'anstallningsform',
  your_profession_does_not_match_the_vacancy_profession:
    'Ditt registrerade yrke matchar inte jobbets yrke',
  youre_application_have_been_sent: 'Din ansökan är skickad',
  youve_recently_sent_an_application:
    'Du har nyligen skickat en intresseanmälan.',
}

const tellMeMore: TranslationNamespace<'tellMeMore'> = {
  email: 'Email',
  tell_me_more_completed: 'Skickat!',
  tell_me_more_cta: 'Berätta mer!',
  tell_me_more_subtitle: `Med ${SITE_NAME} kan du enkelt söka jobb och jämföra erbjudanden från 100+ arbetsgivare.`,
  tell_me_more_title: 'Vill du höja din lön?',
}

const contactRequest = {
  already_in_contact: 'Har redan kontakt med företaget',
  contact_request_reject_header: (employerName: string) =>
    `Varför vill du inte ha kontakt med ${employerName}?`,
  contact_request_reject_header_subheader: 'Det är frivilligt att svara',
  decline: 'Avböj',
  dislike_company: 'Gillar inte företaget',
  do_you_want_accept_the_contact_request: 'Vill du acceptera förfrågan?',
  free_text_extra_comment: 'Något du vill tillägga?',
  free_text_extra_comment_placeholder: 'Därför att... (fritext)',
  reason: 'Anledning',
  select: 'Välj...',
}

const profile: TranslationNamespace<'profile'> = {
  about_you: 'Om dig',
  accept: 'Acceptera',
  add_authorization: 'Lägg till legitimation',
  add_authorization_description: 'Lägg till ditt legitimationsnummer',
  add_authorization_error: 'Vänligen ange ett giltigt legitimationsnummer',
  add_authorization_title: 'Mellan 6 och 9 siffror',
  answer: 'Svara',
  application_is_sent: 'Ansökan skickas',
  apply_cta_subtitle: 'Hitta nästa drömuppdrag',
  apply_cta_title: 'Redo för nya uppdrag?',
  authorization: 'Legitimation',
  back: 'Tillbaka',
  bio: 'Biografi',
  close: 'Stäng',
  contacts: 'Kontakter',
  countries: 'Länder',
  current_employee_status: 'Nuvarande anställning',
  decline: 'Avböj',
  delete_document: 'Radera dokument',
  delete_file: (fileName: string) => 'Radera ' + fileName,
  delete_profile: 'Radera profil',
  delete_profile_confirmation:
    'Är du säker på att du vill radera din profil? Detta går inte att ångra.',
  delete_profile_description:
    'Din profil kommer att raderas permanent och kan inte återställas.',
  departments: 'Verksamheter',
  do_you_want_to_upload_this_file: 'Vill du ladda upp filen?',
  document_one: 'Dokument',
  document_other: 'Dokument',
  download_document: 'Ladda ner dokument',
  download_file: (fileName: string) => 'Ladda ner ' + fileName,
  email: 'E-post',
  employers_in_contact_with_you: 'Arbetsgivare som har kontakt med dig',
  experience: 'Erfarenhet',
  file_could_not_be_found: (email: string) =>
    'Filen kunde inte hittas. Kontakta oss på ' + email,
  file_is_uploaded: 'Filen är uppladdad!',
  file_was_not_accepted:
    'Filen accepterades inte, försök gärna med en annan fil',
  hidden_from_employers: 'Dold för arbetsgivare',
  home_municipality: 'Hemort',
  invite_reference: 'Bjud in referenser',
  is_uploading_file: 'Laddar upp filen',
  jobs: 'Jobb',
  journal_systems: 'Journalsystem',
  matching_criteria: 'Matchningskritierier',
  matching_job_emails: 'Mailutskick',
  matching_job_emails_description: 'Mailutskick med matchande uppdrag',
  my_profile: 'Min profil',
  n_years_of_experience: (years: number) => {
    if (!years) return 'Ingen erfarenhet'
    return years === 1 ? 'Ett års erfarenhet' : `${years} års erfarenhet`
  },
  name: 'Namn',
  new_contact_requests_from_employers:
    'Nya kontaktförfrågningar från arbetsgivare',
  no_matching_job_emails: 'Inga mailutskick med matchande uppdrag',
  no_matching_jobs: 'Vi kunde tyvärr inte hitta några matchande jobb',
  no_matching_jobs_description:
    'Genom att uppdatera dina preferenser till att innefatta fler regioner och verksamheter ökar du möjligheterna att hitta ett matchande jobb.',
  norwegian_license: 'Norsk legitimation',
  not_added: 'Ej ifyllt',
  once_we_find_a_job:
    'När vi hittar en match tar bemanningsbolagen kontakt med dig för att diskutera erbjudanden och upplägg.',
  open_for_new_opportunities: 'Öppen för nya möjligheter',
  overview: 'Översikt',
  phone_number: 'Telefonnummer',
  profession: 'Yrke',
  reapply: 'Skicka ny ansökan',
  reapply_explanation:
    'Vi påbörjar en ny matchning och hittar bemanningsföretag som passar din profil.',
  reapply_question: 'Vill du skicka en ny ansökan?',
  receive_job_offers: 'Tar emot anonyma erbjudanden',
  regions: 'Regioner',
  replace_file: (fileName: string) => 'Ersätt ' + fileName,
  replace_with_new_document: 'Ersätt med nytt dokument',
  scope: 'Omfattning',
  see_all_jobs: 'Se alla jobb',
  select_files_to_upload: 'Väljer filer eller dra och släpp',
  specialization: 'Specialisering',
  staffing_agencies_contact_you: 'Bemanningsföretagen kontaktar dig',
  start_date: 'Startdatum',
  swedish_license: 'Svensk legitimation',
  the_more_flexible_you_are:
    'Ju mer flexibel du är desto större är chansen att vi hittar matchande uppdrag. Enklast att få jobb är om du är flexibel med pendling.',
  to_your_profile: 'Till din profil',
  type_of_document: 'Typ av dokument...',
  type_of_employment: 'Typ av anställning',
  update_email: 'Uppdatera email',
  update_name: 'Uppdatera namn',
  update_phone_number: 'Uppdatera telefonnummer',
  update_profile: 'Uppdatera profil',
  upload: 'Ladda upp',
  upload_cv: 'Ladda upp eget CV',
  upload_cv_description: 'Krydda din profil med eget CV',
  upload_document: 'Ladda upp dokument',
  upload_document_description: 'Ladda upp valfritt dokument',
  upload_license: 'Ladda upp legitimation',
  upload_license_description: 'Spara tid för dig själv och bemanningsbolagen',
  upload_new_file: 'Ladda upp ny fil',
  upload_specialization_document: 'Ladda upp specialisering',
  upload_specialization_document_description: '',
  uploaded_by_you_on: (date: string) => 'Uppladdat av dig ' + date,
  we_have_sent_you_application: (companyName?: string) =>
    `Din ansökan har skickats${
      companyName !== '' ? ` till ${companyName}` : ''
    }!`,
  we_use_matching_to_find_jobs:
    'Vi använder matchningskriterna för att automatiskt hitta uppdrag som passar dig.',
  what_does_matching_mean: 'Vad betyder matchningskriterier?',
  what_happens_now: 'Vad händer nu?',
  work_form: 'Önskad anställningsform',
  you_are_not_in_contact_with_anyone: 'Du har inte skapt några kontakter än.',
  you_have_selected: (fileName: string) => 'Du har valt ' + fileName,
  you_pick_which_companies: 'Du väljer det uppdrag som passar dig bäst',
  your_application_has_been_sent: 'Din ansökan är skickad',
}

/**
 * Swedish [sv]
 */
const sv: Translation = {
  auth,
  base,
  blog,
  companiesList,
  companyFaq,
  companyPage,
  companyTable,
  contactRequest,
  cookieConsent,
  ctaPanel,
  deleteProfileRedirect,
  fallback,
  faq,
  footer,
  header,
  iePopup,
  jobBoard,
  jobPage,
  landingPage,
  landingPage2,
  leads,
  menuItems,
  meta,
  notFound,
  phone_number,
  profile,
  registrationForm,
  reviews,
  sentry,
  tellMeMore,
}

export const locale = Locale.SV

export default sv
