"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shiftsToWorkHours = exports.shiftsToHours = exports.shiftToWorkHours = exports.shiftToHours = exports.shiftWorkDuration = void 0;
const date_1 = require("@bemlo/date");
const shiftDuration = (shift) => {
    const startMoment = (0, date_1.parseDateTime)(shift.date, shift.startTime);
    // Check for:
    //  - Night shifts that might cross midnight
    //  - 24 hour shifts
    let endMoment = (0, date_1.parseDateTime)(shift.date, shift.endTime);
    if (endMoment.isBefore(startMoment) || endMoment.isSame(startMoment)) {
        endMoment = endMoment.add(1, 'day');
    }
    return date_1.dayjsEn.duration(endMoment.diff(startMoment));
};
const shiftWorkDuration = (shift) => {
    const shiftDurationExcludingBreak = shiftDuration(shift);
    const breakDuration = date_1.dayjsEn.duration(shift.breakTime, 'minutes');
    return shiftDurationExcludingBreak.subtract(breakDuration);
};
exports.shiftWorkDuration = shiftWorkDuration;
const shiftToHours = (shift) => {
    return shiftDuration(shift).asHours();
};
exports.shiftToHours = shiftToHours;
const shiftToWorkHours = (shift) => {
    return (0, exports.shiftWorkDuration)(shift).asHours();
};
exports.shiftToWorkHours = shiftToWorkHours;
const shiftsToHours = (shifts) => {
    return shifts.reduce((acc, shift) => acc + (0, exports.shiftToHours)(shift), 0);
};
exports.shiftsToHours = shiftsToHours;
const shiftsToWorkHours = (shifts) => {
    return shifts.reduce((acc, shift) => acc + (0, exports.shiftToWorkHours)(shift), 0);
};
exports.shiftsToWorkHours = shiftsToWorkHours;
