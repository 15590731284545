"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shiftTypeStyles = exports.SHIFT_TYPE_STYLES = void 0;
const schedule_1 = require("@bemlo/schedule");
const constants_1 = require("../constants");
exports.SHIFT_TYPE_STYLES = {
    DAY: {
        icon: 'DayIcon',
        color: constants_1.Color.MEDIUM_BLUE,
    },
    EVENING: {
        icon: 'EveningIcon',
        color: constants_1.Color.MEDIUM_RED,
    },
    NIGHT: {
        icon: 'NightIcon',
        color: constants_1.Color.VIOLET,
    },
};
const shiftTypeStyles = (shift) => {
    const { DAY, EVENING, NIGHT, WEEKEND, WEEKEND_NIGHT } = (0, schedule_1.workHoursByShift)(shift);
    const dayHours = DAY + WEEKEND;
    const eveningHours = EVENING;
    const nightHours = NIGHT + WEEKEND_NIGHT;
    const { type } = [
        {
            type: 'DAY',
            hours: dayHours,
        },
        {
            type: 'EVENING',
            hours: eveningHours,
        },
        {
            type: 'NIGHT',
            hours: nightHours,
        },
    ].sort((a, b) => b.hours - a.hours)[0];
    return exports.SHIFT_TYPE_STYLES[type];
};
exports.shiftTypeStyles = shiftTypeStyles;
