"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useWeekFromDate = exports.useWeeksForRange = void 0;
const react_1 = require("react");
const date_1 = require("@bemlo/date");
const translate_1 = require("../../../translate");
/**
 * Prevents an infinite loop if the input is bad (should not happen).
 */
const WEEK_RANGE_MAX_LENGTH = 53;
const useWeeksForRange = (startsAt, endsAt) => {
    const t = (0, translate_1.useTranslations)();
    return (0, react_1.useMemo)(() => {
        const now = (0, date_1.dayjsEn)();
        const startDate = t.$date.unix(startsAt);
        const startWeekDate = startDate.startOf('week');
        const endDate = t.$date.unix(endsAt);
        const endWeekDate = endDate.endOf('week');
        let stepWeekDate = startWeekDate;
        const weeks = [];
        let i = 0;
        while (stepWeekDate.isBefore(endWeekDate) && i < WEEK_RANGE_MAX_LENGTH) {
            const isoWeek = stepWeekDate.isoWeek();
            const days = [];
            const columns = [];
            for (let dayIndex = 0; dayIndex < 7; dayIndex++) {
                const date = stepWeekDate.add(dayIndex, 'day');
                const dateFormatted = date.format(date_1.ISO_DATE_FORMAT);
                const isPast = date.isBefore(now, 'day');
                const withinRange = !date.isBefore(startDate) && !date.isAfter(endDate);
                days.push({
                    date,
                    isPast,
                    dateFormatted,
                    withinRange,
                    disabled: !withinRange,
                });
                columns.push({
                    disabled: !withinRange,
                    key: dateFormatted,
                    text: date.format('ddd **D/M**'),
                    highlight: date.isSame(now, 'day'),
                });
            }
            weeks.push({
                key: stepWeekDate.format('YYYY-WW'),
                number: isoWeek,
                days,
                columns,
            });
            stepWeekDate = stepWeekDate.add(1, 'week');
            i++;
        }
        return weeks;
    }, [startsAt, endsAt, t]);
};
exports.useWeeksForRange = useWeeksForRange;
const useWeekFromDate = (startsAt) => {
    return (0, exports.useWeeksForRange)(date_1.dayjsEn.unix(startsAt).startOf('week').unix(), date_1.dayjsEn.unix(startsAt).endOf('week').unix())[0];
};
exports.useWeekFromDate = useWeekFromDate;
