"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scheduleToShiftsByDate = void 0;
const date_1 = require("@bemlo/date");
const scheduleToShiftsByDate = (schedule) => {
    return schedule.reduce((shiftsByDateAccumulator, shift) => {
        const { start, end } = shift;
        const startDay = start.format(date_1.ISO_DATE_FORMAT);
        const endDay = end.format(date_1.ISO_DATE_FORMAT);
        if (shiftsByDateAccumulator.has(startDay)) {
            shiftsByDateAccumulator.get(startDay).push(shift);
        }
        else {
            shiftsByDateAccumulator.set(startDay, [shift]);
        }
        if (startDay !== endDay) {
            if (shiftsByDateAccumulator.has(endDay)) {
                shiftsByDateAccumulator.get(endDay).push(shift);
            }
            else {
                shiftsByDateAccumulator.set(endDay, [shift]);
            }
        }
        return shiftsByDateAccumulator;
    }, new Map());
};
exports.scheduleToShiftsByDate = scheduleToShiftsByDate;
