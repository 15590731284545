"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PresentationScheduleSelector = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const lodash_1 = require("lodash");
const elements_1 = require("../../elements");
const RowTitle_1 = require("../../elements/Calendar/RowTitle");
const ShiftCellColumn_1 = require("../../elements/Calendar/ShiftCellColumn");
const WeekGrid_1 = require("../../elements/Calendar/WeekGrid");
const WeekTitle_1 = require("../../elements/Calendar/WeekTitle");
const PresentationScheduleSelector = (props) => {
    const weeks = (0, elements_1.useWeeksForRange)(props.startsAt, props.endsAt);
    const shifts = (0, elements_1.useShiftsByDate)(props.rowType === 'NONE'
        ? props.shifts
        : props.shifts.map((shift) => ({ ...shift, rowId: shift.row.toFixed() })));
    const toggleShift = (id) => {
        props.onChange(props.selectedIds.includes(id)
            ? props.selectedIds.filter((i) => i !== id)
            : [...props.selectedIds, id]);
    };
    const rows = Array.from({
        length: ((0, lodash_1.max)(props.shifts.map(({ row }) => row)) ?? 0) + 1,
    }).map((_, row) => row);
    const selectedRows = props.shifts
        .filter(({ id }) => props.selectedIds.includes(id))
        .map(({ row }) => row.toFixed());
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: weeks.map((week, index) => {
            const getCells = (rowId) => week.days.map(({ date, dateFormatted }) => {
                const shift = shifts[dateFormatted]?.[rowId];
                const isSelectable = props.rowType === 'STRICT'
                    ? !selectedRows.length || selectedRows.includes(rowId)
                    : true;
                const cellProps = {
                    key: dateFormatted,
                    shaded: date.isWeekend(),
                    disabled: !isSelectable,
                    node: null,
                };
                if (!shift)
                    return cellProps;
                const isSelected = props.selectedIds.includes(shift.id);
                const node = ((0, jsx_runtime_1.jsx)(ShiftCellColumn_1.ShiftCellColumn, { children: (0, jsx_runtime_1.jsx)(elements_1.ShiftCell, { disabled: !isSelectable, onClick: isSelectable ? () => toggleShift(shift.id) : undefined, startTime: shift.startTime, endTime: shift.endTime, status: !isSelectable
                            ? 'BLOCKED'
                            : shift.type === 'PRESENTATION'
                                ? isSelected
                                    ? 'SELECTED'
                                    : 'VACANT'
                                : isSelected
                                    ? 'REPLACING'
                                    : 'BOOKED', shiftIds: shift.shiftIds }) }));
                return { ...cellProps, node };
            });
            return ((0, jsx_runtime_1.jsx)(WeekGrid_1.WeekGrid, { columns: week.columns, index: index, title: (0, jsx_runtime_1.jsx)(WeekTitle_1.WeekTitle, { week: week.number }), rows: props.rowType === 'NONE'
                    ? props.rows.map(({ id: rowId, ...shiftVariant }) => ({
                        key: rowId,
                        title: (0, jsx_runtime_1.jsx)(elements_1.ShiftVariantTitle, { shift: shiftVariant }),
                        cells: getCells(rowId),
                    }))
                    : rows.map((rowId) => ({
                        key: rowId,
                        title: (0, jsx_runtime_1.jsx)(RowTitle_1.RowTitle, { row: rowId + 1 }),
                        cells: getCells(rowId.toFixed()),
                    })) }, index));
        }) }));
};
exports.PresentationScheduleSelector = PresentationScheduleSelector;
