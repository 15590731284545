"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useErrorsByDate = exports.useShiftsByDate = void 0;
const react_1 = require("react");
const useShiftsByDate = (shifts) => {
    return (0, react_1.useMemo)(() => {
        const structuredShifts = {};
        shifts.forEach((shift) => {
            // Determine the key based on whether row, rowId, or both are present
            const rowKey = 'row' in shift ? `${shift.row}` : '';
            const rowIdKey = 'rowId' in shift ? shift.rowId : '';
            if (!rowKey && !rowIdKey)
                return;
            structuredShifts[shift.date] = structuredShifts[shift.date] || {};
            if (rowKey)
                structuredShifts[shift.date][rowKey] = shift;
            if (rowIdKey)
                structuredShifts[shift.date][rowIdKey] = shift;
        });
        return structuredShifts;
    }, [shifts]);
};
exports.useShiftsByDate = useShiftsByDate;
const useErrorsByDate = (errors) => {
    return (0, react_1.useMemo)(() => {
        const structuredErrors = {};
        errors.forEach((error) => {
            // Determine the key based on whether row, rowId, or both are present
            const rowKey = 'row' in error ? `${error.row}` : '';
            const rowIdKey = 'rowId' in error ? error.rowId : '';
            if (!rowKey && !rowIdKey)
                return;
            structuredErrors[error.date] = structuredErrors[error.date] ?? {};
            if (rowKey)
                structuredErrors[error.date][rowKey] =
                    structuredErrors[error.date][rowKey] ?? [];
            if (rowIdKey)
                structuredErrors[error.date][rowIdKey] =
                    structuredErrors[error.date][rowIdKey] ?? [];
            if (rowKey)
                structuredErrors[error.date][rowKey].push(error);
            if (rowIdKey)
                structuredErrors[error.date][rowIdKey].push(error);
        });
        return structuredErrors;
    }, [errors]);
};
exports.useErrorsByDate = useErrorsByDate;
