"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.overlappingShiftsViolationWhenAddingShiftToExistingSchedule = exports.overlappingShiftsViolationFor = void 0;
const enums_1 = require("@bemlo/enums");
const utils_1 = require("@bemlo/utils");
const overlappingShiftsViolationFor = (shift1, shift2) => {
    const [firstShift, secondShift] = shift1.start.isBefore(shift2.start)
        ? [shift1, shift2]
        : [shift2, shift1];
    if (firstShift.end.isAfter(secondShift.start)) {
        return [
            {
                shiftId: firstShift.id,
                violationType: enums_1.SchedulingViolationType.OVERLAPPING_SHIFTS,
                violatingShift: secondShift,
            },
            {
                shiftId: secondShift.id,
                violationType: enums_1.SchedulingViolationType.OVERLAPPING_SHIFTS,
                violatingShift: firstShift,
            },
        ];
    }
};
exports.overlappingShiftsViolationFor = overlappingShiftsViolationFor;
const overlappingShiftsViolationWhenAddingShiftToExistingSchedule = (newShift, schedule) => {
    return schedule
        .flatMap((shift) => (0, exports.overlappingShiftsViolationFor)(shift, newShift))
        .filter(utils_1.isTruthy)
        .filter((violation) => violation.shiftId === newShift.id);
};
exports.overlappingShiftsViolationWhenAddingShiftToExistingSchedule = overlappingShiftsViolationWhenAddingShiftToExistingSchedule;
