"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.byCellFilter = void 0;
const notEqual = (filter, shift, field) => {
    const filterValue = filter[field];
    const isFilterDefined = !!filterValue || typeof filterValue === 'number';
    return isFilterDefined && filter[field] !== shift[field];
};
const notEqualish = (filter, shift, field) => {
    if (field in filter) {
        return filter[field] != shift[field];
    }
    return false;
};
const byCellFilter = (filter, date) => (shift) => {
    if (notEqual(filter, shift, 'bookedCandidateId'))
        return false;
    if (notEqual(filter, shift, 'rowKey'))
        return false;
    if (notEqual(filter, shift, 'scheduleResourceId'))
        return false;
    if (notEqual(filter, shift, 'startTime'))
        return false;
    if (notEqual(filter, shift, 'endTime'))
        return false;
    if (notEqual(filter, shift, 'breakTime'))
        return false;
    if (notEqualish(filter, shift, 'comment'))
        return false;
    if (!date)
        return true;
    return date === shift.date;
};
exports.byCellFilter = byCellFilter;
