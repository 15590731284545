"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PresentationScheduleCalendar = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const lodash_1 = require("lodash");
const elements_1 = require("../../elements");
const RowTitle_1 = require("../../elements/Calendar/RowTitle");
const ShiftCellColumn_1 = require("../../elements/Calendar/ShiftCellColumn");
const WeekGrid_1 = require("../../elements/Calendar/WeekGrid");
const WeekTitle_1 = require("../../elements/Calendar/WeekTitle");
const translate_1 = require("../../translate");
const answeringStatus = (shift, isSelected) => {
    if (shift.status === 'OCCUPIED' || shift.status === 'REJECTED')
        return 'OCCUPIED';
    return isSelected ? 'SELECTED' : 'PRESENTED';
};
const PresentationScheduleCalendar = (props) => {
    const t = (0, translate_1.useTranslations)();
    const isAnswering = props.mode === 'answer';
    const weeks = (0, elements_1.useWeeksForRange)(props.startsAt, props.endsAt);
    const shifts = (0, elements_1.useShiftsByDate)(props.enforceRows
        ? props.shifts.map((shift) => ({ ...shift, rowId: shift.row.toFixed() })) // Maybe...
        : props.shifts);
    const errors = (0, elements_1.useErrorsByDate)(props.errors ?? []);
    const rows = (0, lodash_1.uniq)(props.shifts.map(({ row }) => row)).sort((a, b) => a - b);
    return ((0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: weeks.map((week, index) => {
            const getCells = (rowId) => week.days.map(({ date, dateFormatted }) => {
                const cellProps = {
                    key: dateFormatted,
                    shaded: date.isWeekend(),
                    node: null,
                };
                const shift = shifts[dateFormatted]?.[rowId];
                const error = errors[dateFormatted]?.[rowId];
                if (!shift)
                    return cellProps;
                const isSelected = !!props.selectedIds?.includes(shift.id);
                const isOccupied = shift.status === 'OCCUPIED' || shift.status === 'REJECTED';
                const handleClick = () => props.onChange?.((curr) => isSelected
                    ? curr.filter((id) => id !== shift.id)
                    : [...curr, shift.id]);
                const node = ((0, jsx_runtime_1.jsx)(ShiftCellColumn_1.ShiftCellColumn, { children: (0, jsx_runtime_1.jsx)(elements_1.ShiftCell, { onClick: isAnswering && !isOccupied ? handleClick : undefined, startTime: shift.startTime, endTime: shift.endTime, disabled: shift.status === 'OCCUPIED', status: isAnswering
                            ? answeringStatus(shift, isSelected)
                            : shift.status, shiftIds: [shift.id], tooltip: error
                            ? {
                                intent: 'danger', // Change this to using DuotoneExclamationCircle?
                                message: (0, lodash_1.uniq)(error.map((e) => t.$schedulingViolationType(e.type))).join(', '),
                            }
                            : undefined }) }));
                return {
                    ...cellProps,
                    node,
                };
            });
            return ((0, jsx_runtime_1.jsx)(WeekGrid_1.WeekGrid, { columns: week.columns, index: index, title: (0, jsx_runtime_1.jsx)(WeekTitle_1.WeekTitle, { week: week.number }), rows: props.enforceRows
                    ? rows.map((rowId) => ({
                        key: rowId,
                        title: (0, jsx_runtime_1.jsx)(RowTitle_1.RowTitle, { row: rowId + 1 }),
                        cells: getCells(rowId.toFixed()),
                    }))
                    : props.rows.map(({ id, ...shiftVariant }) => ({
                        key: id,
                        title: (0, jsx_runtime_1.jsx)(elements_1.ShiftVariantTitle, { shift: shiftVariant }),
                        cells: getCells(id),
                    })) }, index));
        }) }));
};
exports.PresentationScheduleCalendar = PresentationScheduleCalendar;
